import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../atoms/userAtoms';
import { useNavigate } from "react-router-dom"; // For navigation
import "../styles/ExperienceForm.css";

const API_URL = process.env.REACT_APP_API_URL;

const ExperienceForm = () => {
  const user = useRecoilValue(userState);
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate(); // For navigation
  const [characters, setCharacters] = useState([]); // Stores character data
  const [selectedAvatar, setSelectedAvatar] = useState(null); // Selected avatar
  const [step, setStep] = useState(1); // Current step in form flow
  const [file, setFile] = useState(null); // Uploaded file
  const [title, setTitle] = useState(""); // Experience title

  useEffect(() => {
    if (user.credits <= 0) {
      alert("You have no credits left. Redirecting to Buy Credits page...");
      navigate("/credits");
    }
  }, [user.credits, navigate]);

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          console.error("User not authenticated. Please log in.");
          return;
        }
  
        const response = await fetch(`${API_URL}/api/characters`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setCharacters(data); // Store character data, which includes signed URLs for avatar photos
        } else {
          console.error("Failed to fetch characters");
        }
      } catch (error) {
        console.error("Error fetching characters:", error);
      }
    };
  
    fetchCharacters();
  }, []);
  
  // Handle avatar selection and move to next step
  const handleAvatarClick = (characterName, avatar) => {
    setSelectedAvatar({ characterName, ...avatar });
    setStep(2);
  };

  // File upload handler
  const handleDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const handleSubmit = async (e) => {
    // Prevent the default form submission behavior
    e.preventDefault();
    // Check if all required fields are filled
    if (!file || !title || !selectedAvatar) {
      alert("Please fill in all required fields and upload a file.");
      return;
    }
    // Create FormData object to send data to the server
    const formData = new FormData();
    formData.append("title", title);
    formData.append("avatarId", selectedAvatar._id); // Send the avatar ID
    formData.append("file", file);

    try {
      // Send the form data to the server
      const response = await fetch(`${API_URL}/api/experiences`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        body: formData,
      });

      // Check if the response is successful
      if (response.ok) {
        setUser((prev) => ({ ...prev, credits: prev.credits - 1 }));
        resetForm();
        alert("Experience submitted successfully! Processing will happen separately.");
      } else {
        const errorData = await response.json();
        alert(`Failed: ${errorData.message}`);
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      alert("An error occurred while submitting.");
    }
  };

  // Reset the form to its initial state
  const resetForm = () => {
    setStep(1);
    setSelectedAvatar(null);
    setFile(null);
    setTitle("");
  };

  // Set up the dropzone for file upload
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "video/mp4": [".mp4"], // Standard MP4
      "video/hevc": [".mp4", ".hevc"], // HEVC support
      "application/pdf": [".pdf"],
      "application/msword": [".doc", ".docx"],
    },
    maxFiles: 1,
  });

  return (
    <div className="experience-form-container">
      {/* Step 1: Display characters and avatars */}
      {step === 1 && (
        <div className="step-1">
          <h2>Create New Experience</h2>
          <p>Your Available Credits: {user.credits !== null ? user.credits : "Loading..."}</p>
          <div className="character-sections">
            {characters.map((character) => (
              <div key={character._id} className="character-section">
                <h3>{character.name}</h3>
                <div className="avatars-row">
                  {character.avatars.map((avatar, index) => (
                    <div
                      key={index}
                      className={`avatar-container ${
                        selectedAvatar?.photo === avatar.signedPhotoUrl ? "selected" : ""
                      }`}
                      onClick={() => handleAvatarClick(character.name, avatar)}
                    >
                      <img src={avatar.signedPhotoUrl} alt={avatar.name} className="avatar" />
                      <p className="avatar-name">{avatar.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Step 2: File upload */}
      {step === 2 && (
        <div className="step-2">
          <h2>Upload Your Video or Document</h2>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p>
              Drag and drop a file here, or click to select a file
              <br />
              <span>(Supported formats: MP4 (H.264/HEVC), PDF, DOC, DOCX)</span>
            </p>
          </div>
          {file && <p>Uploaded File: {file.name}</p>}
          <button onClick={() => setStep(1)} className="back-button">
            Back
          </button>
          <button onClick={() => setStep(3)} className="next-button" disabled={!file}>
            Next
          </button>
        </div>
      )}

      {/* Step 3: Title input */}
      {step === 3 && (
        <div className="step-3">
          <h2>Title Your Experience</h2>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter a title for your experience"
          />
          <p></p>
          <button onClick={() => setStep(2)} className="back-button">
            Back
          </button>
          <button type="submit" onClick={handleSubmit} className="submit-button">
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default ExperienceForm;
