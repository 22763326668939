import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../atoms/userAtoms';
import { loadStripe } from "@stripe/stripe-js";
import '../styles/Welcome.css';
import WhiteSnowScene from './WhiteSnowScene';

const API_URL = process.env.REACT_APP_API_URL;
const GREETING_VIDEO_URL = 'https://storage.googleapis.com/wondergram-public/wondergram-santa-intro.mp4';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Welcome = () => {
  const [step, setStep] = useState(1);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [childName, setChildName] = useState('');
  const [childWishlist, setChildWishlist] = useState('');
  const [hasInteracted, setHasInteracted] =  useState(false);
  const [showSubmissionForm, setShowSubmissionForm] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState('');
  const videoRef = useRef(null);
  const [user, setUser] = useRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [returnTo, setReturnTo] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showDownload, setShowDownload] = useState(false);
  const [showError, setShowError] = useState(false);
  const [experienceId, setExperienceId] = useState(null);
  const [showSkipButton, setShowSkipButton] = useState(true);


  const loadingMessages = [
    "Loading Santa's response...",
    "Santa is typing...",
    "Santa is thinking...",
    "Santa is working on your message...",
    "Santa is almost ready...",
  ];

  useEffect(() => {
    let messageIndex = 0;
    let loadingMessageInterval;
    let pollingInterval;
  
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('userId');
    const returnToParam = params.get('returnTo');
    const sessionIdParam = params.get('sessionId');
  
    // If the userId is present, set it in the user state
    if (userId) {
      setUser((prev) => ({
        ...prev,
        id: userId,
      }));
    }
    // If the sessionId is present, set it in the sessionId state
    if (sessionIdParam) {
      setSessionId(sessionIdParam);
    }
    // If returnToParam is welcome, set the loading state
    if (returnToParam === "welcome") {
      setReturnTo(true);
      setIsLoading(true);
    }
  
    // Start polling only if isLoading and sessionId are set
    if (isLoading && sessionId) {
      // Show loading messages
      loadingMessageInterval = setInterval(() => {
        setLoadingMessage(loadingMessages[messageIndex]);
        messageIndex = (messageIndex + 1) % loadingMessages.length;
      }, 1000);
  
      // Ensure polling starts only once
      if (!pollingInterval) {
        pollingInterval = setInterval(async () => {
          try {
            console.log("Polling response...");
            const pollingResponse = await pollExperience(sessionId);
  
            if (pollingResponse) {
              console.log("Polling response data:", pollingResponse);
  
              // Check the status of the experience
              if (pollingResponse.status.toLowerCase() === "completed") {
                console.log("Experience completed:", pollingResponse);
                clearInterval(pollingInterval);
                clearInterval(loadingMessageInterval);
                setIsLoading(false);
                setExperienceId(pollingResponse._id);
                setShowDownload(true); // Show the download section
                setStep(3);
              } else if (pollingResponse.status.toLowerCase() === "error") {
                console.log("Experience error:", pollingResponse);
                clearInterval(pollingInterval);
                clearInterval(loadingMessageInterval);
                setIsLoading(false);
                setShowError(true); // Show the error section
                setStep(4);
              }
            }
          } catch (error) {
            console.error("Error during polling:", error.message);
            clearInterval(pollingInterval);
            clearInterval(loadingMessageInterval);
            setIsLoading(false);
            setShowError(true); // Show the error section
            setStep(4);
          }
        }, 3000);
      }
    }
  
    // Cleanup intervals and event listeners
    return () => {
      clearInterval(loadingMessageInterval);
      clearInterval(pollingInterval);
    };
  }, [isLoading, sessionId, returnTo]);

  const handleDownload = () => {
    console.log("handleDownload experienceId", experienceId);
    // download the experience
    const downloadUrl = `${window.location.origin}/download/${experienceId}`;
    window.open(downloadUrl, '_blank');
  };

  const handlePurchase = async (credits, userId) => {
    try {
      const successUrl = `${window.location.origin}?returnTo=welcome&userId=${userId}`;
      const cancelUrl = `${window.location.origin}?returnTo=welcome&userId=${userId}`;

      console.log("handlePurchase credits", credits);
      console.log("handlePurchase userId", userId);
      console.log("handlePurchase successUrl", successUrl);
      console.log("handlePurchase cancelUrl", cancelUrl);

      const experienceData = {
        title: "Welcome to Santa's Magic",
        avatarId: "675560ffc595f364bb856bf4",
        userId: userId,
        text: `Hello Santa, my name is ${childName}. I have the following wish list, ${childWishlist}.`,
      };
  
      const body = {
        credits,
        userId,
        successUrl,
        cancelUrl,
        action: "create-experience",
        actionData: experienceData, // Include the additional experience data
      };
      // open stripe checkout session in new window 
      const response = await fetch(`${API_URL}/api/stripe/create-guest-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();

      if (!data.sessionId) {
        console.error("Failed to create checkout session:", data.message);
        alert("Failed to create checkout session. Please try again.");
        return;
      }

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });

      if (result.error) {
        console.error("Stripe Checkout error:", result.error.message);
        alert("An error occurred during checkout. Please try again.");
      }
    } catch (error) {
      console.error("Error during purchase:", error.message);
      alert("An error occurred. Please try again.");
    }
  };

  const handleSkip = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoRef.current.duration; // Skip to end
    }
    setShowSkipButton(false);
  };

  const handleSubmitFirstExperience = async () => {
    try {
      //setIsLoading(true);
      setSubscriptionError('');

      // Make a subscription API call
      const response = await fetch(`${API_URL}/api/users/create-non-registered-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          name: userName,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("handleSubmitFirstExperience data", data);
        const userId = data.userId; // Get the userId from the response

        setUser((prev) => ({
          ...prev,
          email,
          id: userId,
          parentName: user.userName,
        }));

        console.log("handleSubmitFirstExperience userId", userId);
        // now send the user to stripe to purchase credits
        const purchaseResponse = await handlePurchase(1,userId);
        console.log("handleSubmitFirstExperience purchaseResponse", purchaseResponse);
        if(purchaseResponse){
          // start video polling
          startVideoPolling();
        }

      } else {
        setSubscriptionError(data.message || 'Failed to subscribe. Please try again.');
      }
     
    } catch (error) {
      setSubscriptionError('An error occurred. Please try again.');
      console.error('Error in handleSubmitFirstExperience:', error);
    }
  };

  const startVideoPolling = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };
  
  const handleVideoEnd = () => {
    console.log("handleVideoEnd");
    setShowSubmissionForm(true);
    setStep(2);
  };

  const handleFirstInteraction = () => {
    if (!hasInteracted && videoRef.current) {
      setHasInteracted(true);
      videoRef.current.play();
      videoRef.current.muted = false;
    }
  };

  // poll the experience status
  const pollExperience = async (sessionId) => {
    let pollingResponse = null;
    try {
      const response = await fetch(`${API_URL}/api/experiences/session/${sessionId}`);
      const data = await response.json();
      console.log("pollingExperience data", data);
      if (data._id && data.status) {
        pollingResponse = {status: data.status, _id: data._id};
        // if the status is completed, then we are done polling
        if (data.status.toLowerCase() === "completed") {
          // Update the UI or redirect as needed
          setIsLoading(false);
        }else if (data.status.toLowerCase() === "error") {
          // if the status is error, then we are done polling
          console.log("Experience error:", data.status);
          setIsLoading(false);
        }
      } else {
        // no status, so we are not done polling
        console.log("Experience not ready yet, continuing polling...");
      }
    } catch (error) {
      console.error("Error polling experience status:", error.message);
    }
    console.log("pollingExperience pollingResponse", pollingResponse);
    return pollingResponse;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <WhiteSnowScene />
      <div
        className="welcome-container"
        onClick={!hasInteracted ? handleFirstInteraction : undefined}
      >
        {step === 1 && (
          <div className="intro-step">
            {!isLoading ? (
              <div className="video-container">
                <video
                  ref={videoRef}
                  className="santa-video"
                  playsInline
                  muted={!hasInteracted}
                  controls={hasInteracted}
                  onEnded={() => setShowSubmissionForm(true)}
                  preload="auto"
                >
                  <source src={GREETING_VIDEO_URL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {!hasInteracted && <div className="click-to-play">Click anywhere to start the magic! ✨</div>}
                {showSkipButton && (
                  <button
                    className="skip-button"
                    onClick={handleSkip}
                  >
                    Skip
                  </button>
                )}
                {showSubmissionForm && (
                  <div className="subscription-form">
                    <h3>Join Santa's Nice List! 🎅</h3>
                    <p>Enter your details to continue the magical experience</p>
                    <input
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Parent Name"
                      className="name-input"
                    />
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Parent Email"
                      className="email-input"
                    />
                    <input
                      type="text"
                      value={childName}
                      onChange={(e) => setChildName(e.target.value)}
                      placeholder="Child's First Name"
                      className="child-name-input"
                    />
                    <input
                      type="text"
                      value={childWishlist}
                      onChange={(e) => setChildWishlist(e.target.value)}
                      placeholder="Child's Wishlist"
                      className="child-wishlist-input"
                    />
                    {subscriptionError && <div className="error-message">{subscriptionError}</div>}
                    <button
                      className="submit-button"
                      onClick={handleSubmitFirstExperience}
                      disabled={
                        !userName || !email || !isValidEmail(email) || !childName || !childWishlist
                      }
                    >
                      Continue the Magic ✨
                    </button>
                    <p className="skip-message">By clicking continue, you agree to our <a href="/privacypolicy">Privacy Policy</a> and <a href="/tos">Terms of Service</a>. We will send you two videos to choose from.</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="loading-component">
                <p>{loadingMessage}</p>
              </div>
            )}
          </div>
        )}
        {step === 3 && showDownload && (
          <div className="download-section">
            <h4>Download your video!</h4>
            <p>
              Click the button below to download your video.
            </p>
            <button className="download-button" onClick={handleDownload}>
              Download
            </button>
          </div>
        )}
        {step === 4 && showError && (
          <div className="error-section">
            <h4>Error!</h4>
            <p>
              Your experience will be emailed to you shortly. 
            </p>
          </div>
        )}
      </div>
    </>
  );
  
};

export default Welcome;
